<template>
  <div>
    <v-layout>
      <div class="boxapp">
        <!-- boxapp head -->
        <div class="boxapp-head">
          <a href="#" @click="$router.go(-1)">
            <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
          </a>
          <h1>{{ getProjectTypeName(project.projectTypeId) }}</h1>
        </div>
        <!-- boxapp body -->
        <div class="box-main">
          <Steps :page-options="pageOptions" :project="project" />

          <div class="section">
            <!-- section head -->
            <div class="head">
              <h5>PASSO 4</h5>
              <h4>Cronograma do Projeto</h4>
            </div>
            <v-layout row wrap mw-half mt-5>
              <v-flex>
                <InputDateRange
                  ref="initialFinalDateInput"
                  textLabel="Previsão de início e encerramento do projeto"
                  place-holder="Selecione"
                  v-model="project.initialFinalDates"
                  :monthsToShow="3"
                  :allowSingleDate="false"
                  invalidInputMessage="Preencha a previsão de início e encerramento do projeto"
                  @input="changeProjectDates"
                  @validationStatusChanged="validateStep"
                ></InputDateRange>
              </v-flex>
              <v-flex>
                <div class="yes-no">
                  <label>O projeto está apto para receber recursos?</label>
                  <v-radio-group
                    row
                    @change="captureStatusChanged()"
                    v-model="project.captureStatus"
                  >
                    <v-radio label="Sim" :value="true"></v-radio>
                    <v-radio label="Não" :value="false"></v-radio>
                  </v-radio-group>
                </div>
              </v-flex>
              <v-flex mw-half>
                <InputSelect
                  ref="projectPhaseInput"
                  v-model="project.projectPhaseId"
                  :items="projectPhaseList"
                  textLabel="Selecione a fase do projeto"
                  valueAttribute="id"
                  textAttribute="name"
                  invalidInputMessage="Selecione a fase do projeto"
                  @validationStatusChanged="validateStep"
                ></InputSelect>
              </v-flex>
            </v-layout>
          </div>
          <BorderSeparation />
          <div class="cronograma">
            <h4 class="my-4">Cronograma de Atividades</h4>
            <v-btn
              flat
              round
              color="white"
              class="btn btn-primary px-5"
              large
              @click="openActivityList"
            >
              <i class="mr-2 fal fa-calendar-alt"></i>Gerenciar Atividades
            </v-btn>
            <ActivityList
              ref="activityListComponent"
              :allowEditing="true"
              @validationStatusChanged="validateStep"
            ></ActivityList>
          </div>
        </div>
      </div>
      <div class="box-help-spacer"></div>
      <HelpBox :text="text" />
    </v-layout>
    <FooterStep ref="footer" v-model="pageOptions" :validationMethod="isValid" :project="project" />
    <AucAlert
      ref="alertComponent"
      title="Se trocar a data do projeto, as metas e atividades poderão ser canceladas. Tem certeza que deseja prosseguir?"
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="confirmAlert"
      @cancel="cancelAlert"
    />
  </div>
</template>

<script type="plain/text">
import HelpBox from "@/components/HelpBox.vue";
import BorderSeparation from "@/components/BorderSeparation.vue";
import Steps from "@/components/Steps.vue";
import FooterStep from "@/components/FooterStep.vue";
import CommonHelper from "@/scripts/helpers/common.helper";
import AucAlert from "@/components/AucAlert.vue";
import Activity from "@/components/Activity.vue";
import ActivityList from "@/components/ActivityList.vue";
import Project from "@/scripts/models/project.model";
import ProjectService from "@/scripts/services/project.service";
import {
  ProjectTypes,
  GetProjectTypeName
} from "@/scripts/models/enums/projectTypes.enum.js";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import {
  ProjectPhases,
  ProjectPhasesOnCreationPositiveCaptureStatus,
  ProjectPhasesOnCreationNegativeCaptureStatus
} from "@/scripts/models/enums/projectPhases.enum.js";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum.js";
export default {
  extends: ValidatableComponent,
  components: {
    HelpBox,
    Steps,
    AucAlert,
    BorderSeparation,
    FooterStep,
    Activity,
    ActivityList
  },
  props: ["pageOptions"],
  data() {
    return {
      text: HelpBoxText.ProjectStep4,
      project: new Project(),
      projectService: new ProjectService(),
      teste: "",
      projectPhaseList: [],
      originalDates: [null, null],
      getProjectTypeName: GetProjectTypeName,
      projectValidationStatusIndex: 4,
      breadcrumbLevels: [
        { text: "Início" },
        { text: "Projetos" },
        { text: "Passo 4" }
      ]
    };
  },
  beforeRouteEnter: (to, from, next) => {
    if (to.query.projectId && to.query.projectId != 0) {
      let projectService = new ProjectService();
      projectService.findById(to.query.projectId).then(function(data) {
        if (data.projectTypeId != ProjectTypes.Ticket) {
          to.query.project = data;
          next();
        } else {
          store.commit(
            "SET_SNACKBAR_MESSAGE",
            "Essa página não está disponível para esse tipo de projeto."
          );
          next(false);
        }
      });
    } else {
      next({ name: Routes.app.ProjectStep0 });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.$route.query.saved) {
      this.$route.query.saved = true;
      this.projectService.save(this.project).then(next);
    } else {
      next();
    }
  },
  created() {
    this.project = this.$route.query.project;
    this.originalDates = this.project.initialFinalDates;
    this.$emit("changeLevels", this.breadcrumbLevels);
    this.validationMethod = this.validate;
    this.onValidationSuccessMethod = this.setValidStep;
    this.onValidationFailMethod = this.setInvalidStep;
    this.showProjectPhases();
    this.pageOptions.changeClass("footer");
    this.pageOptions.currentPage = 4;
  },
  watch: {
    "project.initialFinalDates": {
      handler: function(after, before) {
        if (after) {
          this.project.initialDate = after[0];
          this.project.finalDate = after[1];
        } else {
          this.project.initialFinalDates = [null, null];
        }
      },
      deep: true
    }
  },
  methods: {
    openActivityList() {
      this.$refs.activityListComponent.open(this.project);
    },
    captureStatusChanged: function() {
      this.projectService.changeCaptureStatus(
        this.project.id,
        this.project.captureStatus
      );
      this.project.projectPhaseId = 0;
      this.showProjectPhases();
    },
    showProjectPhases: function() {
      if (this.project.captureStatus)
        this.projectPhaseList = ProjectPhasesOnCreationPositiveCaptureStatus;
      else this.projectPhaseList = ProjectPhasesOnCreationNegativeCaptureStatus;
    },
    changeProjectDates(dates) {
      if (
        dates == null ||
        dates[0] != this.project.initialDate ||
        dates[1] != this.project.finalDate
      ) {
        if (
          this.project.goalList.length > 0 ||
          this.project.scheduleList.length > 0
        ) {
          this.$refs.alertComponent.open();
        }
      }
    },
    confirmAlert() {
      this.projectService
        .changeProjectDate(
          this.project.id,
          this.project.initialDate,
          this.project.finalDate
        )
        .then(
          function(data) {
            debugger;
            if (data.success) {
              CommonHelper.swal(
                "Data do projeto alterado com sucesso.",
                null,
                "success"
              );
            } else {
              CommonHelper.swal("Erro ao alterar a data do projeto.");
            }
          }.bind(this)
        );
    },
    cancelAlert() {
      this.project.initialFinalDates = this.originalDates;
    },
    // saveChanges() {
    //   this.projectService.save(this.project).then(
    //     function(data) {
    // 		debugger;
    //       this.project = data;
    //     }.bind(this)
    //   );
    // },
    setValidStep() {
      this.$set(this.project.stepsValidationStatus, "step4", true);
    },
    setInvalidStep() {
      this.$set(this.project.stepsValidationStatus, "step4", false);
    },
    validateStep() {
      let valid = this.isValid(false);
      if (valid) this.setValidStep();
      else this.setInvalidStep();
    },
    validate(performMethods) {
      return this.validateComponents(performMethods);
      // let valid = this.validateComponents(performMethods);
      // if (!valid) return false;
      // if (
      //   this.project.scheduleList == null ||
      //   this.project.scheduleList.length < 3
      // ) {
      //   if (performMethods) {
      //     this.$store.commit(
      //       "SET_SNACKBAR_MESSAGE",
      //       "É necessário pelo menos três atividades"
      //     );
      //   }
      //   return false;
      // }
      // return true;
    }
  }
};
</script>