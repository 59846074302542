<template>
  <div class="footer-step">
    <v-btn
      flat
      round
      color="white"
      class="btn-default flex-inherit px-5"
      large
      v-model="pageOptions"
      @click="prev"
    >{{previousText}}</v-btn>
    <div class="btn-right">
      <v-btn
        flat
        round
        color="white"
        class="btn-primary flex-inherit px-5"
        large
        v-model="pageOptions"
        @click="next"
      >{{nextText}}</v-btn>
      <!-- <v-btn 
      color="white"
      large
      flat
      round
      class="btn-secondary flex-inherit px-5"
      >Concluir</v-btn> -->
    </div>
  </div>
</template>

<script type="plain/text">
import { Routes } from "@/scripts/models/enums/routes.enum";
import ProjectService from "@/scripts/services/project.service";
export default {
  props: [
    "value",
    "validationMethod",
    "nextLabelText",
    "previousLabelText",
    "project"
  ],
  data() {
    return {
      pages: [
        Routes.app.ProjectStep0,
        Routes.app.ProjectStep1,
        Routes.app.ProjectStep2,
        Routes.app.ProjectStep3,
        Routes.app.ProjectStep4,
        Routes.app.ProjectStep50,
        Routes.app.ProjectStep51,
        Routes.app.ProjectGallery
      ],
      pageOptions: this.value,
      previousText: this.previousLabelText,
      nextText: this.nextLabelText,
      projectService: new ProjectService()
    };
  },
  created() {
    if (!this.previousLabelText) {
      this.previousText = "Voltar";
    }
    if (!this.nextLabelText) {
      this.nextText = "Próximo";
    }
  },
  methods: {
    prev() {
      this.pageOptions.currentPage -= 1;
      this.$emit("clickPreview");
      if (!this.project.id) {
        this.$router.push({ name: this.pages[this.pageOptions.currentPage] });
      } else {
        this.$router.push({
          name: this.pages[this.pageOptions.currentPage],
          query: { projectId: this.project.id }
        });
      }
    },
    next() {
      // let valid = this.validationMethod();
      // if (valid) {
      if(this.validationMethod()) {
        this.pageOptions.currentPage += 1;
        this.$emit("clickNext");
        // this.projectService.save(this.project).then(
        //   function(data) {
        //     if (!data.id) {
        //       this.$router.push({
        //         name: this.pages[this.pageOptions.currentPage]
        //       });
        //     } else {
        this.$router.push({
          name: this.pages[this.pageOptions.currentPage],
          query: {projectId: this.project.id}
        });
        //     }
        //   }.bind(this)
        // );
        // }
      }
    }
  }
};
</script>