<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" scrollable max-width="900px">
      <v-card>
        <v-card-title class="headline">
          <h1>Cronograma de Atividades</h1>
          <a href="#"></a>
          <v-btn class="close-modal" @click="dialog = false">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 550px;">
          <v-layout w100 d-flex>
            <v-flex xs12 class="pt-4 border-horizontal display-flex f-d-column">
              <v-btn
                v-if="allowEditing"
                flat
                round
                color="white"
                class="d-flex ml-auto btn btn-primary p-425-0-25 px-4 mb-2"
                large
                @click="newActivity"
              >
                <i class="far fa-plus mr-2"></i>Nova atividade
              </v-btn>
              <div class="list-scroll mt-4">
                <v-flex
                  d-flex
                  box-list-activity
                  v-for="(schedule, index) in project.scheduleList"
                  :key="index"
                >
                  <div class="mr-4 t-767-center">
                    <FileImage :file="schedule.file" :alt="''" />
                  </div>
                  <div class="w-100 pr-3">
                    <v-layout class="justify-space-between content-listactivity">
                      <v-flex head-listactivity>
                        <h2>{{schedule.name}}</h2>
                        <template v-for="(period, index2) in schedule.schedulePeriodList">
                          <span
                            :key="index2"
                          >{{ period.initialFinalDates[1] ? formatDate(period.initialFinalDates[0]) + ' - ' + formatDate(period.initialFinalDates[1]) : formatDate(period.initialFinalDates[0]) }}</span>
                          <br :key="'br' + index2">
                        </template>
                      </v-flex>
                      <v-flex shrink no-wrap list-tools>
                        <a href="#" @click="blockUnblockActivity(index)" v-if="allowEditing">
                          <i class="fal fa-ban" v-show="!schedule.blocked"></i>
                          <i class="fal fa-ban" style="color:$red" v-show="schedule.blocked"></i>
                        </a>
                        <a href="#" @click="editActivity(index)" v-if="allowEditing">
                          <i class="fal fa-pen"></i>
                        </a>
                        <a
                          href="#"
                          @click="openConfirmDeleteActivityModal(schedule)"
                          v-if="allowEditing"
                        >
                          <i class="fal fa-trash"></i>
                        </a>
                      </v-flex>
                    </v-layout>
                    <p class="text-default">{{schedule.description}}</p>
                  </div>
                </v-flex>
                <AucAlert
                  ref="activityAlert"
                  title="Você tem certeza que deseja excluir essa atividade?"
                  confirmButtonText="Sim"
                  cancelButtonText="Não"
                  @confirm="deleteActivity(selectedSchedule)"
                />
              </div>
              <Activity
                ref="activityComponent"
                v-model="project.scheduleList"
                :projectId="project.id"
                :initialFinalDates="project.initialFinalDates"
                @close="dialog = true"
              ></Activity>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script type="plain/text">
import { format } from "date-fns";
import Activity from "@/components/Activity.vue";
import FileImage from "@/components/FileImage.vue";
import Schedule from "@/scripts/models/schedule.model";
import SchedulePeriod from "@/scripts/models/schedulePeriod.model";
import { GetCurrentOrder } from "@/scripts/helpers/list.helper";
import AucAlert from "@/components/AucAlert.vue";
import FileService from "@/scripts/services/file.service";
import Project from "@/scripts/models/project.model";
export default {
  components: {
    Activity,
    FileImage,
    AucAlert
  },
  props: ["allowEditing"],
  data() {
    return {
      project: new Project(),
      dialog: false,
      creatingNewActivity: false,
      selectedSchedule: null,
      validationStatus: false,
      fileService: new FileService()
    };
  },
  // watch: {
  //   "project.scheduleList": {
  //     handler: function(after, before) {
  //       this.validate();
  //     },
  //     deep: true
  //   }
  // },
  methods: {
    open(selectedProject) {
      this.project = selectedProject;
      this.dialog = true;
    },
    openConfirmDeleteActivityModal(item) {
      this.selectedSchedule = item;
      this.$refs.activityAlert.open();
    },
    newActivity() {
      this.creatingNewActivity = true;
      let schedule = new Schedule();
      schedule.schedulePeriodList = [new SchedulePeriod()];
      this.$refs.activityComponent.open(schedule);
      this.dialog = false;
    },
    blockUnblockActivity(index) {
      let schedule = this.project.scheduleList[index];
      this.$set(
        this.project.scheduleList[index],
        "Blocked",
        !this.project.scheduleList[index].blocked
      );
    },
    editActivity(index) {
      let schedule = this.project.scheduleList[index];
      if (!schedule.blocked) {
        this.dialog = false;
        this.creatingNewActivity = false;
        this.$refs.activityComponent.open(schedule);
      }
    },
    deleteActivity(item) {
      if (!item.blocked) {
        this.$refs.activityComponent.remove(item.id);
      }
    },
    validate() {
      if (this.project.scheduleList && this.project.scheduleList.length > 2) {
        if (!this.validationStatus) this.$emit("validationStatusChanged", true);
        this.validationStatus = true;
      } else {
        if (this.validationStatus) this.$emit("validationStatusChanged", false);
        this.validationStatus = false;
      }
    },
    formatDate(currentDate) {
      return format(currentDate, "DD/MM/YYYY", {
        locale: require("date-fns/locale/pt")
      });
    },
    getImage(index) {
      if (this.validURL(this.project.scheduleList[index].file.path)) {
        this.fileService.render(this.project.scheduleList[index].file.id).then(
          function(data) {
            this.project.scheduleList[index].file.path = data;
            return data;
          }.bind(this)
        );
      } else {
        return this.project.scheduleList[index].file.path;
      }
    },
    validURL(str) {
      var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
      if (!regex.test(str)) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>
