const ProjectPhases = {
    ProjetosEmCadastramento: 0,
    AbertoParaReceberDoacoes: 1,
    Execucao: 2,
    ProjetoEmAprovacao: 3,
    LiberacaoDeRecursos: 4,
    Encerrado: 5,
    Cancelado: 6,
};

const AllProjectPhases = [
    { id: ProjectPhases.ProjetosEmCadastramento, name: "Projeto em cadastramento" },
    { id: ProjectPhases.AbertoParaReceberDoacoes, name: "Aberto para receber doações" },
    { id: ProjectPhases.Execucao, name: "Execução" },
    { id: ProjectPhases.ProjetoEmAprovacao, name: "Projeto em aprovação" },
    { id: ProjectPhases.LiberacaoDeRecursos, name: "Liberação de recursos" },
    { id: ProjectPhases.Encerrado, name: "Encerrado" },
    { id: ProjectPhases.Cancelado, name: "Cancelado" }
];

const ProjectPhasesOnCreationPositiveCaptureStatus = [
    { id: ProjectPhases.AbertoParaReceberDoacoes, name: "Aberto para receber doações" },
    { id: ProjectPhases.Execucao, name: "Execução" }
];

const ProjectPhasesOnCreationNegativeCaptureStatus = [
    { id: ProjectPhases.Execucao, name: "Execução" },
    { id: ProjectPhases.ProjetoEmAprovacao, name: "Projeto em Aprovação" },
    { id: ProjectPhases.LiberacaoDeRecursos, name: "Liberação de Recursos" }
]

const ProjectPhasesText = {
    ProjetosEmCadastramento: { id: ProjectPhases.ProjetosEmCadastramento, name: "Projeto em cadastramento" },
    AbertoParaReceberDoacoes: { id: ProjectPhases.AbertoParaReceberDoacoes, name: "Aberto para receber doações" },
    Execucao: { id: ProjectPhases.Execucao, name: "Execução" },
    ProjetoEmAprovacao: { id: ProjectPhases.ProjetoEmAprovacao, name: "Projeto em aprovação" },
    LiberacaoDeRecursos: { id: ProjectPhases.LiberacaoDeRecursos, name: "Liberação de recursos" },
    Encerrado: { id: ProjectPhases.Encerrado, name: "Encerrado" },
    Cancelado: { id: ProjectPhases.Cancelado, name: "Cancelado" }
}

export { AllProjectPhases, ProjectPhases, ProjectPhasesOnCreationPositiveCaptureStatus, ProjectPhasesOnCreationNegativeCaptureStatus, ProjectPhasesText };
