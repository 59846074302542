export default class SchedulePeriod {

    constructor() {
        this.Order = 0;
        this.id = 0;
        this.intialDate = null;
        this.finalDate = null;
        this.initialFinalDates = [];
        this.scheduleId = 0;
        this.statusId = 0;
        this.description = "";
        this.schedulePeriodFiles = [];
    }
}