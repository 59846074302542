<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="730">
      <template></template>
      <v-card>
        <v-card-title class="headline">
          <h1>Nova atividade</h1>
          <v-btn class="close-modal" @click="close">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <div class="pa-3">
          <v-layout w100>
            <v-flex xs5 pr-3>
              <div class="upload-image" id="preview" ref="preview">
                <label :for="'upload-schedule' + projectId">
                  <i class="fas fa-camera-alt"></i>
                </label>
                <label :for="'upload-schedule' + projectId" class="do-upload" v-show="schedule.file.path == ''">
                  <i class="fal fa-cloud-upload"></i>Faça Upload
                </label>
                <input
                  type="file"
                  :id="'upload-schedule' + projectId"
                  accept="image/*"
                  @change="fileChanged"
                  ref="upload_schedule"
                />
              </div>
            </v-flex>
            <v-flex xs7 pr-2>
              <InputText
                ref="nameInput"
                type="text"
                place-holder="Atividade"
                textLabel="Nome da atividade"
                helpLabel="Ex: Mutirão de combate à dengue"
                v-model="schedule.name"
                :focusOnCreate="true"
                labelCharLimit="50"
                invalidInputMessage="Preencha o nome da atividade"
              />
              <div class="txtarea-labels input-comp">
                <label>Descrição</label>
                <InputTextArea
                  ref="descriptionInput"
                  rows="2"
                  place-holder="Escreva sobre esta atividade do projeto"
                  v-model="schedule.description"
                  labelAreaHelp="Ex: Ribeirão Preto"
                  invalidInputMessage="Preencha a descrição da faixa"
                  labelAreaCharLimit="250"
                ></InputTextArea>
              </div>
              <v-layout
                mt-4
                v-for="(period, index) in schedule.schedulePeriodList"
                input-comp
                :key="period.Order"
              >
                <InputDateRange
                  ref="periodInput"
                  place-holder="Selecione"
                  v-model="period.initialFinalDates"
                  :monthsToShow="1"
                  textLabel="Data/Período"
                  :index="index"
                  :min="initialFinalDates[0]"
                  :max="initialFinalDates[1]"
                  :allowSingleDate="true"
                  invalidInputMessage="Preencha o período da faixa"
                ></InputDateRange>
                <v-flex xs2 class="btn-plus-modal">
                  <a
                    :class="{'pb-23':$refs.periodInput && $refs.periodInput[index] && !$refs.periodInput[index].valid}"
                    href="#"
                    @click="removePeriod(index)"
                    v-if="index != schedule.schedulePeriodList.length - 1"
                  >
                    <i class="fal fa-trash"></i>
                  </a>
                  <a
                    :class="{'pb-23':$refs.periodInput && $refs.periodInput[index] && !$refs.periodInput[index].valid}"
                    href="#"
                    @click="addPeriod"
                    v-if="index == schedule.schedulePeriodList.length - 1"
                  >
                    <i class="fal fa-plus-circle"></i>
                  </a>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
        <v-card-actions class="pa-0">
          <div class="footer-modal-atividades">
            <v-btn flat round color="white" class="btn-default px-5" large @click="close">Cancelar</v-btn>
            <v-btn flat round color="white" class="btn-primary px-5" large @click="isValid">Salvar</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script type="plain/text">
import Schedule from "@/scripts/models/schedule.model";
import SchedulePeriod from "@/scripts/models/schedulePeriod.model";
import { GetCurrentOrder } from "@/scripts/helpers/list.helper";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import ProjectService from "@/scripts/services/project.service";
export default {
  extends: ValidatableComponent,
  props: ["projectId", "initialFinalDates"],
  data() {
    return {
      dialog: false,
      projectService: new ProjectService(),
      schedule: new Schedule(),
      originalSchedule: null
    };
  },
  created() {
    this.onValidationSuccessMethod = this.validationSuccess;
    this.validationMethod = this.validate;
  },
  methods: {
    listByProjectId() {
      this.projectService.listScheduleByProjectId(this.projectId).then(
        function(data) {
          this.$emit("input", data);
        }.bind(this)
      );
    },
    save() {
      this.originalSchedule.projectId = this.projectId;
      this.projectService.saveSchedule(this.refreshList, this.originalSchedule);
    },
    remove(scheduleId) {
      this.projectService.deleteSchedule(this.refreshList, scheduleId);
    },
    refreshList() {
      this.listByProjectId();
    },
    validate(performMethods) {      
      if (this.schedule.file == null || this.schedule.file.path == "") {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "Escolha uma imagem para a atividade"
        );
        return false;
      }
      let valid = this.validateComponents(performMethods);
      if (!valid) return false;
      for (let i = 0; i < this.$refs.periodInput.length; i++) {
        valid = this.validateComponent(
          this.$refs.periodInput[i],
          performMethods
        );
        if (!valid) return valid;
      }
      return true;
    },
    validationSuccess() {
      this.copy(this.schedule, this.originalSchedule);
      this.save();
      this.close();
    },
    close() {
      this.dialog = false;
      this.copy(new Schedule(), this.schedule);
      this.$refs.preview.style.backgroundImage = "";
      this.$emit("close");
    },
    open(item) {
      this.dialog = true;
      this.originalSchedule = item;
      this.copy(this.originalSchedule, this.schedule);
      this.$refs.preview.style.backgroundImage =
        "url(" + this.schedule.file.path + ")";
      this.$refs.nameInput.showValidationMessage = false;
      this.$refs.descriptionInput.showValidationMessage = false;
    },
    addPeriod() {
      let newPeriod = new SchedulePeriod();
      newPeriod.Order = GetCurrentOrder(this.schedule.schedulePeriodList);
      this.schedule.schedulePeriodList.push(newPeriod);
    },
    removePeriod(index) {
      this.schedule.schedulePeriodList.splice(index, 1);
    },
    fileChanged(event) {
      var file = event.target.files[0];
      if (file) {
        let reader = new FileReader();
        this.schedule.file.name = file.name;
        reader.onload = function() {
          this.$refs.preview.style.backgroundImage = "url(" + reader.result + ")";
          this.$set(this.schedule.file, 'path', reader.result);
        }.bind(this);
        reader.readAsDataURL(file);
      }
    },
    copy(from, to) {
      to.Order = from.Order;
      to.id = from.id;
      to.projectId = from.projectId;
      to.name = from.name;
      to.description = from.description;
      to.schedulePeriodList = [];
      to.file.id = from.file.id;
      to.file.userId = from.file.userId;
      to.file.name = from.file.name;
      to.file.path = from.file.path;
      to.file.size = from.file.size;
      to.file.extension = from.file.extension;
      to.file.origin = from.file.origin;
      for (let i = 0; i < from.schedulePeriodList.length; i++) {
        let schedulePeriod = new SchedulePeriod();
        this.copyPeriod(from.schedulePeriodList[i], schedulePeriod);
        to.schedulePeriodList.push(schedulePeriod);
      }
    },
    copyPeriod(from, to) {
      to.Order = from.Order;
      to.id = from.id;
      to.initialFinalDates = [];
      if (from.initialFinalDates.length == 2) {
        to.initialDate = from.initialFinalDates[0];
        to.initialFinalDates[0] = from.initialFinalDates[0];
        if (from.initialFinalDates[1]) {
          to.finalDate = from.initialFinalDates[1];
          to.initialFinalDates[1] = from.initialFinalDates[1];
        }
      } else {
        to.initialDate = from.initialFinalDates[0];
        to.initialFinalDates[0] = from.initialFinalDates[0];
      }
    }
  }
};
</script>