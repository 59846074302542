import File from "@/scripts/models/file.model";
export default class Schedule {

    constructor() {
        this.Order = 0;
        this.id = 0;
        this.name = '';
        this.description = '';
        this.schedulePeriodList = [];
        this.file = new File();
        this.blocked = false;
        this.projectId = 0;
    }
}